import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import riverData from "./river-data.json";
import boundaries from "./boundaries.json";

const viewState = {
  longitude: 174.7762,
  latitude: -41.2865,
  zoom: 6,
  pitch: 45,
  bearing: 0
};

const root = document.getElementById('root');

ReactDOM.render(
  <App viewState={viewState} 
        riverData={riverData} 
        baseData={boundaries.vectorQuery.layers["50204"]} 
  />, root
);

