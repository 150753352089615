import DeckGL from '@deck.gl/react';
import {GeoJsonLayer} from '@deck.gl/layers';
import React from "react";

const App = ({baseData, riverData, viewState}) => {
  /**
   * Data format:
   * Valid GeoJSON object
   */
  const baseLayer = new GeoJsonLayer({
    id: 'base-layer',
    data: baseData,
    stroked: true,
    filled: true,
    extruded: true,
    lineWidthScale: 20,
    getLineColor:  [95, 103, 105, 255],
    lineWidthMinPixels: 2,
    getFillColor: [95, 103, 105],
    getRadius: 100,
    getLineWidth: 5
  });

  const riverLayer = new GeoJsonLayer({
    id: 'river-layer',
    data: riverData,
    stroked: true,
    extruded: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 2,
    getLineColor: [113, 145, 146, 255],
    getRadius: 100,
    getLineWidth: 1,
    getElevation: 30,
  });

  return (<DeckGL 
    controller={true} 
    initialViewState={viewState} 
    layers={[baseLayer, riverLayer]}
  />);
};

export default App;